@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat Black"), local("Montserrat-Black"), url("/static/source/Montserrat/Montserrat-Black.woff2") format("woff2"), url("/static/source/Montserrat/Montserrat-Black.woff") format("woff"), url("/static/source/Montserrat/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("/static/source/Montserrat/Montserrat-Bold.woff2") format("woff2"), url("/static/source/Montserrat/Montserrat-Bold.woff") format("woff"), url("/static/source/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat Extra Bold"), local("Montserrat-Extra-Bold"), url("/static/source/Montserrat/Montserrat-ExtraBold.woff2") format("woff2"), url("/static/source/Montserrat/Montserrat-ExtraBold.woff") format("woff"), url("/static/source/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat Extra Light"), local("Montserrat-Extra-Light"), url("/static/source/Montserrat/Montserrat-ExtraLight.woff2") format("woff2"), url("/static/source/Montserrat/Montserrat-ExtraLight.woff") format("woff"), url("/static/source/Montserrat/Montserrat-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat Light"), local("Montserrat-Light"), url("/static/source/Montserrat/Montserrat-Light.woff2") format("woff2"), url("/static/source/Montserrat/Montserrat-Light.woff") format("woff"), url("/static/source/Montserrat/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("/static/source/Montserrat/Montserrat-Medium.woff2") format("woff2"), url("/static/source/Montserrat/Montserrat-Medium.woff") format("woff"), url("/static/source/Montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("/static/source/Montserrat/Montserrat-Regular.woff2") format("woff2"), url("/static/source/Montserrat/Montserrat-Regular.woff") format("woff"), url("/static/source/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat Semi Bold"), local("Montserrat-Semi-Bold"), url("/static/source/Montserrat/Montserrat-SemiBold.woff2") format("woff2"), url("/static/source/Montserrat/Montserrat-SemiBold.woff") format("woff"), url("/static/source/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }
